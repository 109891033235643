(function ($) {
    if ($('input.datepicker.firstDayOff').length) {
        $.get('/Ajax/NonBookableDays', function (result) {
            var firstDayOffPicker = null,
                lastDayOffPicker = null;

            firstDayOffPicker = $('input.datepicker.firstDayOff').datepicker({
                autoclose: true,
                weekStart: 1,
                startDate: '+1d',
                daysOfWeekDisabled: result.RegionDaysOff,
                datesDisabled: result.BookedHolidays,
                format: 'dd/mm/yyyy',
                beforeShowDay: function (date) {
                    if (publicHolidaysContainDate(result.PublicHolidays, date)) {
                        return {
                            classes: 'public-holiday',
                            tooltip: 'Public holiday'
                        };
                    }
                }
            }).on('changeDate', function (evt) {
                var newStartDate = firstDayOffPicker.datepicker('getUTCDate');
                newStartDate.setDate(newStartDate.getDate());
                lastDayOffPicker.prop('disabled', false);
                lastDayOffPicker.datepicker('setStartDate', newStartDate);

                var lastDay = $('input.datepicker.lastDayOff').datepicker('getUTCDate');
                if (lastDay !== null) {
                    dayTypeValidation(newStartDate, lastDay);
                }

            });

            lastDayOffPicker = $('input.datepicker.lastDayOff').datepicker({
                autoclose: true,
                weekStart: 1,
                startDate: '+1d',
                daysOfWeekDisabled: result.RegionDaysOff,
                datesDisabled: result.BookedHolidays,
                format: 'dd/mm/yyyy',
                beforeShowDay: function (date) {
                    if (publicHolidaysContainDate(result.PublicHolidays, date)) {
                        return {
                            classes: 'public-holiday',
                            tooltip: 'Public holiday'
                        };
                    }
                }
            }).on('changeDate', function (evt) {
                var firstDay = firstDayOffPicker.datepicker('getUTCDate'),
                    lastDay = lastDayOffPicker.datepicker('getUTCDate'),
                    postData = {
                        startUtc: formatDate(firstDay),
                        endUtc: formatDate(lastDay),
                    };
                firstDayOffPicker.datepicker('setEndDate', lastDay);
                dayTypeValidation(firstDay, lastDay);

                $.post('/Ajax/NumberOfDaysRequired', postData, function (result) {
                    $('#leaveRqd strong span').text(result.DaysRequired);
                    if (result.IsValid) {
                        $('#btnRequest').prop('disabled', null);
                    } else {
                        $('#btnRequest').prop('disabled', 'disabled');
                    }
                });
            });
        });
    }

    if ($('#pnlTeamRequests').length) {
        $('#pnlTeamRequests .panel a').on('click', function (e) {
            e.preventDefault();
            var panel = $(this).next('.collapse');
            var panelHeight = panel[0].scrollHeight;
            if (panel.css('max-height') == '0px') {
                panel.css('max-height', panelHeight);
            } else {
                panel.css('max-height', '0px');
            }
        });
    }

    if ($('.deferred-datepicker').length) {
        $('.deferred-datepicker').each(function (idx, ele) {
            var target = $(ele),
                postData = {
                    startDateUtc: target.attr('data-startdate'),
                    endDateUtc: target.attr('data-enddate')
                },
                startDateJs = stringToDate(postData.startDateUtc),
                endDateJs = stringToDate(postData.endDateUtc);

            $.post('/Ajax/TeamHolidaysOverlapping', postData, function (result) {
                target.datepicker({
                    defaultViewDate: {
                        year: startDateJs.getFullYear(),
                        month: startDateJs.getMonth(),
                        day: startDateJs.getDate()
                    },
                    beforeShowDay: function (date) {
                        var idx = 0,
                            tooltipContent = '',
                            cssClass = '';

                        if (date >= startDateJs && date <= endDateJs) {
                            cssClass = 'booked-date';
                        }

                        if (result.otherRequests.length) {
                            for (idx = 0; idx < result.otherRequests.length; idx++) {
                                var startDate = new Date(parseInt(result.otherRequests[idx].StartDate.substr(6))),
                                    endDate = new Date(parseInt(result.otherRequests[idx].EndDate.substr(6)));

                                if (date >= startDate && date <= endDate) {
                                    tooltipContent += result.otherRequests[idx].FirstName + ' ' + result.otherRequests[idx].LastName + ' (' + formatDate(startDate) + ' - ' + formatDate(endDate) + ')\n';
                                    if (cssClass.indexOf('team-booked') == -1) {
                                        cssClass = 'team-booked ' + cssClass;
                                    }
                                }
                            }
                        }

                        if (cssClass !== '') {
                            return {
                                classes: cssClass,
                                tooltip: tooltipContent
                            };
                        }
                    }
                });
            });
        });
    }



    var dateRadios = jQuery("input:radio[name=DayType]");
    dateRadios.change(function () {

        var dayType = document.querySelector('input[name = "DayType"]:checked').value;


        if ($('input.datepicker.firstDayOff').length) {
            $.get('/Ajax/NonBookableDays', function (result) {
                var firstDayOffPicker = null,
                    lastDayOffPicker = null;

                firstDayOffPicker = $('input.datepicker.firstDayOff').datepicker({
                    autoclose: true,
                    weekStart: 1,
                    startDate: '+1d',
                    daysOfWeekDisabled: result.RegionDaysOff,
                    datesDisabled: result.BookedHolidays,
                    format: 'dd/mm/yyyy',
                    beforeShowDay: function (date) {
                        if (publicHolidaysContainDate(result.PublicHolidays, date)) {
                            return {
                                classes: 'public-holiday',
                                tooltip: 'Public holiday'
                            };
                        }
                    }
                }).on('changeDate', function (evt) {
                    var newStartDate = firstDayOffPicker.datepicker('getUTCDate');
                    newStartDate.setDate(newStartDate.getDate());
                    lastDayOffPicker.datepicker('setStartDate', newStartDate);
                });

                lastDayOffPicker = $('input.datepicker.lastDayOff').datepicker({
                    autoclose: true,
                    weekStart: 1,
                    startDate: '+1d',
                    daysOfWeekDisabled: result.RegionDaysOff,
                    datesDisabled: result.BookedHolidays,
                    format: 'dd/mm/yyyy',
                    beforeShowDay: function (date) {
                        if (publicHolidaysContainDate(result.PublicHolidays, date)) {
                            return {
                                classes: 'public-holiday',
                                tooltip: 'Public holiday'
                            };
                        }
                    }
                }).on('changeDate', function (evt) {
                    var firstDay = firstDayOffPicker.datepicker('getUTCDate')
                    lastDay = lastDayOffPicker.datepicker('getUTCDate'),
                        postData = {
                            startUtc: formatDate(firstDay),
                            endUtc: formatDate(lastDay)
                        };

                    $.post('/Ajax/NumberOfDaysRequired', postData, function (result) {
                        $('#leaveRqd strong span').text(result.DaysRequired);
                        if (result.IsValid) {
                            $('#btnRequest').prop('disabled', null);
                        } else {
                            $('#btnRequest').prop('disabled', 'disabled');
                        }
                    });
                });

                var firstDay = firstDayOffPicker.datepicker('getUTCDate')
                var lastDay = lastDayOffPicker.datepicker('getUTCDate'),
                    postData = {
                        startUtc: formatDate(firstDay),
                        endUtc: formatDate(lastDay),
                        dayType: dayType
                    };

                $.post('/Ajax/NumberOfDaysRequired', postData, function (result) {
                    $('#leaveRqd strong span').text(result.DaysRequired);
                    if (result.IsValid) {
                        $('#btnRequest').prop('disabled', null);
                    } else {
                        $('#btnRequest').prop('disabled', 'disabled');
                    }
                });

            });
        }
    });



}(jQuery.noConflict()));


function dayTypeValidation(firstDay, lastDay) {
    var fullDay = document.getElementById("fullDay");
    var am = document.getElementById("am");
    var pm = document.getElementById("pm");
    var amText = document.getElementById("amText");
    var pmText = document.getElementById("pmText");

    if (firstDay.valueOf() === lastDay.valueOf()) {
        am.style.display = "inline";
        amText.style.display = "inline";
        pm.style.display = "inline";
        pmText.style.display = "inline";
        am.removeAttribute("disabled");
        fullDay.style.display = "inline";
        pm.removeAttribute("disabled");
        amText.style.cursor = 'pointer';
        pmText.style.cursor = 'pointer';
        am.style.cursor = 'pointer';
        pm.style.cursor = 'pointer';
    } else {
        am.style.display = "none";
        amText.style.display = "none";
        pm.style.display = "none";
        pmText.style.display = "none";
        am.setAttribute("disabled", true);
        pm.setAttribute("disabled", true);
        fullDay.checked = true;
        fullDay.style.display = "none";
        amText.style.cursor = 'not-allowed';
        pmText.style.cursor = 'not-allowed';
        am.style.cursor = 'not-allowed';
        pm.style.cursor = 'not-allowed';
    }
}


