(function ($) {
if ($('#rptFrom').length) {
    $('#rptFrom').datepicker({
        format: 'yyyy-mm-dd',
        autoclose: true,
        weekStart: 1
    });
}
if ($('#rptTo').length) {
    $('#rptTo').datepicker({
        format: 'yyyy-mm-dd',
        autoclose: true,
        weekStart: 1
    });
    }
}(jQuery.noConflict()));