(function ($) {
    if ($('#SelectedCategory').val() == "Other") {
        $('#OtherCategory').removeClass("hidden");
    }
    else {
        $('#OtherCategory').addClass("hidden");
    }

    $('#SelectedCategory').on('change', function () {
        if ($(this).val() == "Other") {
            $('#OtherCategory').removeClass("hidden");
        }
        else {
            $('#OtherCategory').addClass("hidden");
        }
    });
}(jQuery.noConflict()));