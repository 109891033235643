(function ($) {
    $(document).ready(function () {
        setSVGcolor();

        function setSVGcolor() {
            var startColors = ['#f29401', '#70ecf0', '#fcdaf9'];
            var endColors = ['#fce80d', '#18a6b9', '#f2917d'];
            var colorIndex = Math.floor(Math.random() * startColors.length);

            $(".logo-gradient-start").attr('stop-color', startColors[colorIndex]);
            $(".logo-gradient-stop").attr('stop-color', endColors[colorIndex]);

            setTimeout(setSVGcolor, 10000);
        }
    });
}(jQuery.noConflict()));