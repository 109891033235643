function formatDate(theDate) {
    return theDate.getFullYear() + '-' + (theDate.getMonth() + 1) + '-' + theDate.getDate();
}

function stringToDate(theDate) {
    var parts = theDate.split('/');
    return new Date(parts[2], parseInt(parts[1]) - 1, parts[0]);
}

function publicHolidaysContainDate(hols, theDate) {
    for (var i = 0; i < hols.length; i++) {
        if (theDate.getTime() == stringToDate(hols[i].DateOfHoliday).getTime()) {
            return true;
        }
    }
    return false;
}

document.addEventListener("DOMContentLoaded", function () {
    (function deleteAbsenceConfirmation() {
        var delBtns = document.querySelectorAll(".deleteBtn");
        var dialog = document.querySelector(".confirm-modal");

        if (delBtns !== null && dialog !== null) {
            var confirm = document.querySelector(".confirm");
            var cancel = document.querySelector(".cancel");
            dialogPolyfill.registerDialog(dialog);
            var delBtn = {};

            for (var i = 0; i < delBtns.length; i++) {
                delBtns[i].addEventListener("click", function (e) {
                    e.preventDefault();
                    delBtn = {
                        action: e.target.getAttribute("href")
                    };
                    openModal();
                });
            }

            cancel.addEventListener("click", function () {
                dialog.close();
            });
        }

        function openModal() {
            dialog.showModal();
            confirm.addEventListener("click", setConfirmAction);
        }

        function setConfirmAction() {
            dialog.close();
            window.location.href = delBtn.action;
        }

    })();

    (function deleteEmployeeConfirmation() {
        var delBtns = document.querySelectorAll(".deleteEmployee");
        var dialog = document.querySelector(".confirm-modal");

        if (delBtns !== null && dialog !== null) {
            var confirm = document.querySelector(".confirm");
            var cancel = document.querySelector(".cancel");
            dialogPolyfill.registerDialog(dialog);
            var delBtn = {};

            for (var i = 0; i < delBtns.length; i++) {
                delBtns[i].addEventListener("click", function (e) {
                    e.preventDefault();
                    delBtn = {
                        action: e.target.getAttribute("href")
                    };
                    openModal();
                });
            }

            cancel.addEventListener("click", function () {
                dialog.close();
            });
        }

        function openModal() {
            dialog.showModal();
            confirm.addEventListener("click", setConfirmAction);
        }

        function setConfirmAction() {
            dialog.close();
            window.location.href = delBtn.action;
        }

    })();
});