(function ($) {
    $('#newPassword,#newPassword2').keyup(function () {
        var newPassword1 = $('#newPassword').val();
        var newPassword2 = $('#newPassword2').val();
        if (newPassword1 == '' || (newPassword1 != newPassword2)) {
            $('#updatePasswordButton').attr('disabled', 'disabled');
            $('#wrongPasswordErrorMessage').removeClass('hidden');
        } else {
            $('#updatePasswordButton').removeAttr('disabled');
            $('#wrongPasswordErrorMessage').addClass('hidden');
        }
    });

    if ($('input.datepicker.firstDayOff').length && $('#MemberId').val() != null) {
        var postData = { memberId: $('#MemberId').val() };

        $.get('/Ajax/NonBookableDaysByAdmin', postData, function (result) {
            var firstDayOffPicker = null,
                lastDayOffPicker = null;

            firstDayOffPicker = $('input.datepicker.firstDayOff').datepicker({
                autoclose: true,
                weekStart: 1,
                daysOfWeekDisabled: result.RegionDaysOff,
                datesDisabled: result.BookedHolidays,
                format: 'dd/mm/yyyy',
                beforeShowDay: function (date) {
                    if (publicHolidaysContainDate(result.PublicHolidays, date)) {
                        return {
                            classes: 'public-holiday',
                            tooltip: 'Public holiday'
                        };
                    }
                }
            }).on('changeDate', function (evt) {
                var newStartDate = firstDayOffPicker.datepicker('getUTCDate');
                newStartDate.setDate(newStartDate.getDate());
                lastDayOffPicker.datepicker('setStartDate', newStartDate);
            });

            lastDayOffPicker = $('input.datepicker.lastDayOff').datepicker({
                autoclose: true,
                weekStart: 1,
                startDate: '+1d',
                daysOfWeekDisabled: result.RegionDaysOff,
                datesDisabled: result.BookedHolidays,
                format: 'dd/mm/yyyy',
                beforeShowDay: function (date) {
                    if (publicHolidaysContainDate(result.PublicHolidays, date)) {
                        return {
                            classes: 'public-holiday',
                            tooltip: 'Public holiday'
                        };
                    }
                }
            }).on('changeDate', function (evt) {
                var firstDay = firstDayOffPicker.datepicker('getUTCDate'),
                lastDay = lastDayOffPicker.datepicker('getUTCDate'),
                postData = {
                    startUtc: formatDate(firstDay),
                    endUtc: formatDate(lastDay),
                    memberId: $('#MemberId').val()
                };

                $.post('/Ajax/NumberOfDaysRequiredByAdmin', postData, function (result) {
                    $('#leaveRqd strong span').text(result.DaysRequired);
                    if (result.IsValid) {
                        $('#btnRequest').prop('disabled', null);
                    } else {
                        $('#btnRequest').prop('disabled', 'disabled');
                    }
                });
            });
        });
    }
}(jQuery.noConflict()));